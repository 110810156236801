import React from "react";
import classNames from "classnames";
import { Container } from "@atoms";
import { m } from "framer-motion";

const Stats = ({ stats }) => {
  const container = {
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
    show: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: {
      opacity: 0,
      y: "50%",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      y: "0%",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };

  return (
    <Container>
      <m.ul
        variants={container}
        initial="hidden"
        whileInView="show"
        viewport={{ margin: "0px 0px -15% 0px" }}
        className="grid grid-cols-1 gap-10 md:grid-cols-3"
      >
        {stats.map((s, i) => {
          const { stat, label, color } = s;
          return (
            <li
              key={stat + label}
              className="flex flex-col items-center justify-start gap-4"
            >
              <m.span
                variants={item}
                className={classNames(
                  "font-heading text-5xl font-bold leading-none sm:text-6xl",
                  {
                    "text-teal": color === "teal" || !color,
                    "text-purple": color === "purple",
                    "text-pink": color === "pink",
                  }
                )}
              >
                {stat}
              </m.span>
              <span className="text-center font-heading font-bold">
                {label}
              </span>
            </li>
          );
        })}
      </m.ul>
    </Container>
  );
};

export default Stats;
